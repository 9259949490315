body:not(.edit_mode) {

  .splash-page {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    // background-image: url(../images/splash-bg.jpg);
    background:
    linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 5%, rgba(0,0,0,0) 95%, rgba(0,0,0,1)100%),
    linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,1)100%),
    url(../images/splash-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    transition: all 300ms ease-out;

    .heroPhotoElement {
      width: 25%;
      margin: 5% auto 0;
      float: none;

      img {
        border: none;
      }
    }

    .events-text {
      width: 50%;
      margin: 20px auto 0;

      h4 {
        width: 55%;
        float: left;
        text-align: right;
        margin-right: 10px;
        font-size: 30px;
        font-weight: 100;
        color: #ffffff;
        letter-spacing: 0.2em;
      }

      h5 {
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 0.2em;
      }
    }

    .textBlockElement {
      h3 {
        text-align: center !important;
        font-weight: 100;
        color: #ffffff;
        width: 200px;
        letter-spacing: .2em;
        line-height: 40px;
        font-size: 18px;
        margin: 75px auto;
        cursor: pointer;
        // From Photoshop
        border-style: solid;
        border-width: 2px;
        border-color: rgb(158, 132, 48);
        border-radius: 10px;
        box-shadow:
          0px 3px 10px 0px rgba(0, 0, 0, 0.75),
          inset -5px 5px 10px rgba(255, 255, 255, 0.35),
          inset 5px -5px 10px rgba(0, 0, 0, 0.25);
        background-color: rgb(158, 132, 48);
        transition: box-shadow 200ms ease-out;

        &:hover {
          // background-color: rgba(165, 137, 46, 1);
          box-shadow:
            0px 3px 10px 0px rgba(0, 0, 0, 0.75),
            inset -5px 5px 10px rgba(0, 0, 0, 0.35),
            inset 5px -5px 10px rgba(0, 0, 0, 0.35);
        }
      }
    }

    .splash-description {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
  }
}

//mobile adjustments

html.has-mobile-nav {
  .splash-page {
    .heroPhotoElement {
      margin: 50px auto 0;
      width: 50%;
    }

    .events-text {
      width: 100%;
      margin: 20px auto 0;

      .text {
        display: flex;
        justify-content: center;

        h4 {
          width: auto;
          float: none;
          font-size: 1.4em;
          letter-spacing: 0.1em;
        }
        h5 {
          font-size: 1.4em;
          letter-spacing: 0.1em;
        }
      }
    }
    .textBlockElement {
      h3 { margin: 50px auto; }
    }
  }
}

// edit mode styling

body.edit_mode {
  .splash-page {
    background:
    linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 5%, rgba(0,0,0,0) 95%, rgba(0,0,0,1)100%),
    linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,1)100%),
    url(../images/splash-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    transition: all 300ms ease-out;

    .heroPhotoElement {
      width: 25%;
      margin: 150px auto 0 !important;
      float: none;

      img {
        border: none;
      }
    }
    h4,h5,h3 {
      color: #ffffff;
      text-align: center !important;
    }
  }
}
